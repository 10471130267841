import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollt zum Anfang der Seite
  };

  return (
    <footer className="bg-gray-800 text-gray-200 py-10">
      <div className="container mx-auto px-6 md:flex md:justify-between">
        {/* Anschrift */}
        <div className="mb-6 md:mb-0">
          <h3 className="text-xl font-semibold mb-2">Anschrift</h3>
          <p>
            Eventservice Taeger<br />
            Auf der Heide 4<br />
            32051 Herford
          </p>
        </div>

        {/* Kontakt */}
        <div className="mb-6 md:mb-0">
          <h3 className="text-xl font-semibold mb-2">Kontakt</h3>
          <p>
            <a href="mailto:eventservice.taeger@gmail.com" className="hover:underline">
              eventservice.taeger@gmail.com
            </a><br />
            Telefon: <a href="tel:015164696160" className="hover:underline">0151 64696160</a><br />
            {/* WhatsApp-Link */}
            WhatsApp:{" "}
            <a
              href="https://wa.me/4915164696160"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
            >
              Nachricht senden
            </a>
          </p>

          {/* Link zum Kontaktformular */}
          <p className="mt-4">
            <Link to="/kontakt" className="hover:underline" onClick={handleLinkClick}>
              Zum Kontaktformular
            </Link>
          </p>
        </div>

        {/* Informationen */}
        <div>
          <h3 className="text-xl font-semibold mb-2">Informationen</h3>
          <ul>
            <li>
              <Link to="/impressum" className="hover:underline" onClick={handleLinkClick}>
                Impressum
              </Link>
            </li>
            <li>
              <Link to="/datenschutz" className="hover:underline" onClick={handleLinkClick}>
                Datenschutzerklärung
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* Copyright */}
      <div className="mt-10 text-center text-gray-200 text-sm">
        &copy; {new Date().getFullYear()} Eventservice Taeger. Alle Rechte vorbehalten.
      </div>
    </footer>
  );
}

export default Footer;
