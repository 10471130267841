import React from 'react';

function AblaufSection() {
  return (
    <div className="container mx-auto px-6 py-8">
      <h2 className="text-3xl font-bold text-center mb-8">So läuft es ab</h2>
      <div className="space-y-8">
        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3 className="text-xl font-bold mb-2">1. Unverbindlich Anfragen</h3>
          <p className="text-gray-700">
            Ihr könnt HUGO ganz einfach und unverbindlich über unser Kontaktformular anfragen. Wenn HUGO verfügbar ist, erhaltet ihr ein maßgeschneidertes Angebot, das 7 Tage lang gültig bleibt.
          </p>
        </div>

        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3 className="text-xl font-bold mb-2">2. Bestätigung und Termin buchen</h3>
          <p className="text-gray-700">
            Ihr habt euch entschieden? Bestätigt das Angebot und bucht den Termin verbindlich.
          </p>
        </div>

        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3 className="text-xl font-bold mb-2">3. Wünsche angeben</h3>
          <p className="text-gray-700">
            Besondere Wünsche bis 14 Tage vor der Veranstaltung schriftlich mitteilen. Keine Sorge, wir melden uns rechtzeitig!
          </p>
        </div>

        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3 className="text-xl font-bold mb-2">4. Lieferung und Aufbau</h3>
          <p className="text-gray-700">
            Wir liefern HUGO ca. 2 Stunden vor der Veranstaltung, bauen ihn auf und geben eine Einweisung. Während des Events sind wir erreichbar.
          </p>
        </div>

        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3 className="text-xl font-bold mb-2">5. Ihr feiert und genießt</h3>
          <p className="text-gray-700">
            Jetzt wird gefeiert – und HUGO versorgt euch mit köstlichen Cocktails.
          </p>
        </div>

        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3 className="text-xl font-bold mb-2">6. Abholung und Abbau</h3>
          <p className="text-gray-700">
            Am nächsten Tag holen wir HUGO wieder ab und nehmen alles mit – als wäre nichts gewesen!
          </p>
        </div>
      </div>
    </div>
  );
}

export default AblaufSection;
