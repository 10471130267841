import React from 'react';
import { Helmet } from 'react-helmet-async'; // Helmet für SEO

function Datenschutz() {
  return (
    <div className="container mx-auto px-6 py-8">
      {/* React Helmet für SEO */}
      <Helmet>
        <title>Datenschutzerklärung - Eventservice Taeger</title>
        <meta
          name="description"
          content="Datenschutzerklärung des Eventservice Taeger. Erfahren Sie, wie wir Ihre personenbezogenen Daten erheben, verarbeiten und schützen."
        />
        <meta name="keywords" content="Datenschutz, Eventservice Taeger, personenbezogene Daten, DSGVO, Datenverarbeitung, Hosting, Rechte, Datenschutzrecht" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://eventservice-taeger.de/datenschutz" />
      </Helmet>

      <h1 className="text-3xl font-bold mb-4">Datenschutz&shy;erkl&auml;rung</h1>
      
      <h2 className="text-2xl font-bold mb-4">1. Datenschutz auf einen Blick</h2>

      <h3 className="text-xl font-semibold mb-2">Allgemeine Hinweise</h3>
      <p className="text-gray-700 mb-4">
        Die folgenden Hinweise geben einen einfachen &Uuml;berblick dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen. Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
      </p>

      <h3 className="text-xl font-semibold mb-2">Datenerfassung auf dieser Website</h3>

      <h4 className="text-lg font-semibold mb-2">Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?</h4>
      <p className="text-gray-700 mb-4">
        Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Abschnitt &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in dieser Datenschutzerkl&auml;rung entnehmen.
      </p>

      <h4 className="text-lg font-semibold mb-2">Wie erfassen wir Ihre Daten?</h4>
      <p className="text-gray-700 mb-4">
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
      </p>
      <p className="text-gray-700 mb-4">
        Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
      </p>

      <h4 className="text-lg font-semibold mb-2">Wof&uuml;r nutzen wir Ihre Daten?</h4>
      <p className="text-gray-700 mb-4">
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur Analyse Ihres Nutzerverhaltens verwendet werden.
      </p>

      <h4 className="text-lg font-semibold mb-2">Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</h4>
      <p className="text-gray-700 mb-4">
        Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein Recht, die Berichtigung oder L&ouml;schung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.
      </p>
      <p className="text-gray-700 mb-4">Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit an uns wenden.</p>

      <h3 className="text-xl font-semibold mb-2">Analyse-Tools und Tools von Dritt&shy;anbietern</h3>
      <p className="text-gray-700 mb-4">
        Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit sogenannten Analyseprogrammen.
      </p>
      <p className="text-gray-700 mb-4">Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerkl&auml;rung.</p>

      <h2 className="text-2xl font-bold mb-4">2. Hosting</h2>

      <p className="text-gray-700 mb-4">Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</p>

      <h3 className="text-xl font-semibold mb-2">Externes Hosting</h3>
      <p className="text-gray-700 mb-4">
        Diese Website wird extern gehostet. Die personenbezogenen Daten, die auf dieser Website erfasst werden, werden auf den Servern des Hosters / der Hoster gespeichert. Hierbei kann es sich v.&nbsp;a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten, die &uuml;ber eine Website generiert werden, handeln.
      </p>
      <p className="text-gray-700 mb-4">
        Das externe Hosting erfolgt zum Zwecke der Vertragserf&uuml;llung gegen&uuml;ber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TDDDG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endger&auml;t des Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TDDDG umfasst. Die Einwilligung ist jederzeit widerrufbar.
      </p>

      <p className="text-gray-700 mb-4">
        Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit verarbeiten, wie dies zur Erf&uuml;llung seiner Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.
      </p>

      <p className="text-gray-700 mb-4">Wir setzen folgende(n) Hoster ein:</p>

      <p className="text-gray-700 mb-4">
        Eventservice Taeger<br />
        Auf der Heide 4<br />
        32051 Herford
      </p>

      <h4 className="text-lg font-semibold mb-2">Auftragsverarbeitung</h4>
      <p className="text-gray-700 mb-4">
        Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
      </p>

      <h2 className="text-2xl font-bold mb-4">3. Allgemeine Hinweise und Pflicht&shy;informationen</h2>

      <h3 className="text-xl font-semibold mb-2">Datenschutz</h3>
      <p className="text-gray-700 mb-4">
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerkl&auml;rung.
      </p>
      <p className="text-gray-700 mb-4">
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir erheben und wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem Zweck das geschieht.
      </p>
      <p className="text-gray-700 mb-4">
        Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.&nbsp;B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.
      </p>

      <h3 className="text-xl font-semibold mb-2">Hinweis zur verantwortlichen Stelle</h3>
      <p className="text-gray-700 mb-4">
        Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser Website ist:
      </p>
      <p className="text-gray-700 mb-4">
        Jonas Taeger<br />
        Auf der Heide 4<br />
        32051 Herford
      </p>
      <p className="text-gray-700 mb-4">
        Telefon: 015164696160<br />
        E-Mail: eventservice.taeger@gmail.com
      </p>
      <p className="text-gray-700 mb-4">
        Verantwortliche Stelle ist die nat&uuml;rliche oder juristische Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen, E-Mail-Adressen o. &Auml;.) entscheidet.
      </p>

      <p className="text-gray-700 mt-4">
        Quelle:{" "}
        <a href="https://www.e-recht24.de" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
          https://www.e-recht24.de
        </a>
      </p>
    </div>
  );
}

export default Datenschutz;
