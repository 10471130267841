import React, { useState } from 'react';
import emailjs from 'emailjs-com';

function AnfrageSection() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    telefonnummer: '',
    veranstaltungsdatum: '',
    veranstaltungsort: '',
    ungefaehreAnzahlGaeste: '', // Ungefähre Anzahl der Gäste
    artVeranstaltung: '',
    nachricht: '',
    datenschutz: false,
    lieferadresse: '' // Unsichtbares Feld für Spam-Check
  });

  const [emailSent, setEmailSent] = useState(false);
  const [errors, setErrors] = useState({});
  const [lastSubmissionTime, setLastSubmissionTime] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  // E-Mail-Validierung
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Telefonnummer-Validierung
  const validatePhone = (telefonnummer) => {
    const phoneRegex = /^[0-9\-\+ ]{7,15}$/;
    return phoneRegex.test(telefonnummer);
  };

  // Datum-Validierung (in der Zukunft)
  const validateDate = (datum) => {
    const today = new Date();
    const eventDate = new Date(datum);
    return eventDate > today;
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.name) newErrors.name = 'Name ist erforderlich';
    if (!formData.email || !validateEmail(formData.email)) newErrors.email = 'Gültige E-Mail ist erforderlich';
    if (!formData.telefonnummer || !validatePhone(formData.telefonnummer)) newErrors.telefonnummer = 'Gültige Telefonnummer ist erforderlich';
    if (!formData.veranstaltungsdatum || !validateDate(formData.veranstaltungsdatum)) newErrors.veranstaltungsdatum = 'Datum muss in der Zukunft liegen';
    if (!formData.veranstaltungsort) newErrors.veranstaltungsort = 'Veranstaltungsort ist erforderlich';
    if (!formData.ungefaehreAnzahlGaeste) newErrors.ungefaehreAnzahlGaeste = 'Ungefähre Anzahl der Gäste ist erforderlich';
    if (!formData.datenschutz) newErrors.datenschutz = 'Bitte akzeptieren Sie die Datenschutzerklärung';
    if (formData.lieferadresse) newErrors.lieferadresse = 'Spamverdacht'; // Spam-Check
    return newErrors;
  };

  // Rate-Limiting: maximal 2 Anfragen pro Minute
  const canSubmit = () => {
    if (!lastSubmissionTime) return true;
    const now = new Date();
    const timeDiff = (now - lastSubmissionTime) / 1000 / 60; // Differenz in Minuten
    return timeDiff >= 0.5;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else if (!canSubmit()) {
      setErrors({ rateLimit: 'Sie können maximal 2 Anfragen pro Minute senden.' });
    } else {
      emailjs.send('service_jmdellb', 'template_5h9946c', formData, 'qF8qUtoQU62Mzjs8D')
        .then(() => {
          setEmailSent(true);
          setFormData({
            name: '',
            email: '',
            telefonnummer: '',
            veranstaltungsdatum: '',
            veranstaltungsort: '',
            ungefaehreAnzahlGaeste: '', // Zurücksetzen
            artVeranstaltung: '',
            nachricht: '',
            datenschutz: false,
            lieferadresse: ''
          });
          setLastSubmissionTime(new Date());
          setErrors({});
        }, (error) => {
          console.log('FAILED...', error);
        });
    }
  };

  return (
    <div className="container mx-auto px-6 py-8">
      <h2 className="text-3xl font-bold text-center mb-8">Unverbindliche Anfrage</h2>
      <p className="text-gray-700 text-center mb-4">Felder mit * sind Pflichtfelder</p>
      <form onSubmit={handleSubmit} className="bg-white p-6 shadow-lg rounded-lg max-w-lg mx-auto">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Name *</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={`w-full px-3 py-2 border rounded ${errors.name ? 'border-red-500' : ''}`}
            required
          />
          {errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">E-Mail *</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={`w-full px-3 py-2 border rounded ${errors.email ? 'border-red-500' : ''}`}
            required
          />
          {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Telefonnummer *</label>
          <input
            type="tel"
            name="telefonnummer"
            value={formData.telefonnummer}
            onChange={handleChange}
            className={`w-full px-3 py-2 border rounded ${errors.telefonnummer ? 'border-red-500' : ''}`}
            required
          />
          {errors.telefonnummer && <p className="text-red-500 text-xs italic">{errors.telefonnummer}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Veranstaltungsdatum *</label>
          <input
            type="date"
            name="veranstaltungsdatum"
            value={formData.veranstaltungsdatum}
            onChange={handleChange}
            className={`w-full px-3 py-2 border rounded ${errors.veranstaltungsdatum ? 'border-red-500' : ''}`}
            required
          />
          {errors.veranstaltungsdatum && <p className="text-red-500 text-xs italic">{errors.veranstaltungsdatum}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Veranstaltungsort *</label>
          <input
            type="text"
            name="veranstaltungsort"
            value={formData.veranstaltungsort}
            onChange={handleChange}
            className={`w-full px-3 py-2 border rounded ${errors.veranstaltungsort ? 'border-red-500' : ''}`}
            required
          />
          {errors.veranstaltungsort && <p className="text-red-500 text-xs italic">{errors.veranstaltungsort}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Ungefähre Anzahl der Gäste *</label>
          <input
            type="number"
            name="ungefaehreAnzahlGaeste"
            value={formData.ungefaehreAnzahlGaeste}
            onChange={handleChange}
            className={`w-full px-3 py-2 border rounded ${errors.ungefaehreAnzahlGaeste ? 'border-red-500' : ''}`}
            required
          />
          {errors.ungefaehreAnzahlGaeste && <p className="text-red-500 text-xs italic">{errors.ungefaehreAnzahlGaeste}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Art der Veranstaltung (optional)</label>
          <input
            type="text"
            name="artVeranstaltung"
            value={formData.artVeranstaltung}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Nachricht (optional)</label>
          <textarea
            name="nachricht"
            value={formData.nachricht}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded"
            rows="4"
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            <input
              type="checkbox"
              name="datenschutz"
              checked={formData.datenschutz}
              onChange={handleChange}
              className="mr-2"
              required
            />
            Ich stimme den Datenschutzbestimmungen zu *
          </label>
          {errors.datenschutz && <p className="text-red-500 text-xs italic">{errors.datenschutz}</p>}
        </div>

        {/* Unsichtbares Feld für Spam-Check */}
        <div style={{ display: 'none' }}>
          <input
            type="text"
            name="lieferadresse"
            value={formData.lieferadresse}
            onChange={handleChange}
          />
        </div>

        <div className="text-center">
          <button type="submit" className="bg-blue-600 text-white font-bold py-2 px-4 rounded">
            Anfrage senden
          </button>
        </div>

        {/* Erfolgsnachricht */}
        {emailSent && <p className="text-green-500 text-center mt-4">Ihre Anfrage wurde erfolgreich gesendet!</p>}
        
        {/* Fehlernachricht für Rate-Limiting */}
        {errors.rateLimit && <p className="text-red-500 text-center mt-4">{errors.rateLimit}</p>}
      </form>
    </div>
  );
}

export default AnfrageSection;
