import React from 'react';

function FeaturesSection() {
  return (
    <div className="container mx-auto px-6 py-8">
      <h2 className="text-3xl font-bold text-center mb-8">Was kann HUGO?</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3 className="text-xl font-bold mb-2">Mehr als 90 Cocktails</h3>
          <p className="text-gray-700">
            Mit unserer Standardbestückung kann HUGO über 90 verschiedene alkoholische und alkoholfreie Cocktails mixen.
          </p>
        </div>
        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3 className="text-xl font-bold mb-2">Innovatives Touchinterface</h3>
          <p className="text-gray-700">
            HUGO ist mit einem leicht zu bedienenden Touchinterface ausgestattet – intuitiv und schnell.
          </p>
        </div>
        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3 className="text-xl font-bold mb-2">Individuelle Cocktails</h3>
          <p className="text-gray-700">
            Sie möchten einen besonderen Cocktail? Gerne bringen wir ihn HUGO bei – Ihre individuellen Wünsche stehen im Mittelpunkt.
          </p>
        </div>
        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3 className="text-xl font-bold mb-2">Nur Markenqualität</h3>
          <p className="text-gray-700">
            Wir verwenden ausschließlich hochwertige Markensäfte und Markenspirituosen – für Cocktails in höchster Qualität.
          </p>
        </div>
        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3 className="text-xl font-bold mb-2">Kein Barkeeper nötig</h3>
          <p className="text-gray-700">
            Dank der automatisierten Funktionen von HUGO brauchen Sie keinen Barkeeper – HUGO übernimmt das Mixen.
          </p>
        </div>
        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3 className="text-xl font-bold mb-2">Self-Service für Gäste</h3>
          <p className="text-gray-700">
            HUGO ist ein echtes Highlight: Ihre Gäste können sich ihre Cocktails selbst mixen – ein unterhaltsames Erlebnis!
          </p>
        </div>
        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3 className="text-xl font-bold mb-2">Stärke der Cocktails einstellbar</h3>
          <p className="text-gray-700">
            Passen Sie die Stärke der Cocktails individuell an – für ein optimales Trinkerlebnis.
          </p>
        </div>
        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3 className="text-xl font-bold mb-2">Geringer Platzbedarf</h3>
          <p className="text-gray-700">
            HUGO benötigt eine Fläche von maximal 2x2 Metern. Der Aufstellort muss wettergeschützt sein und über eine handelsübliche Steckdose verfügen.
          </p>
        </div>
        <div className="bg-white p-6 shadow-lg rounded-lg">
  <h3 className="text-xl font-bold mb-2">Digitale Cocktailkarte</h3>
  <p className="text-gray-700">
    Gäste können über einen QR-Code bequem auf die digitale Cocktailkarte zugreifen. Dank integrierter Such- und Filterfunktionen findet jeder schnell seinen nächsten Drink.
  </p>
</div>
      </div>
    </div>
  );
}

export default FeaturesSection;
