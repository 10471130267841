import React from 'react';
import { Helmet } from 'react-helmet-async'; // Helmet importieren für SEO
import CarouselSection from '../components/CarouselSection';
import TestimonialsSection from '../components/TestimonialsSection';
import AblaufSection from '../components/AblaufSection';
import FAQSection from '../components/FAQSection';
import CocktailkarteSection from '../components/CocktailkarteSection';
import PreislisteSection from '../components/PreislisteSection';
import FeaturesSection from '../components/FeaturesSection';
import AnfrageSection from '../components/AnfrageSection';

function Cocktailmaschine() {
  return (
    <div>
      {/* React Helmet für SEO */}
      <Helmet>
        <title>Cocktailmaschine HUGO mieten – Perfekte Drinks für jede Feier | Eventservice Taeger</title>
        <meta name="description" content="Mieten Sie die innovative Cocktailmaschine HUGO – Cocktails auf Knopfdruck, frisch gemixt für Hochzeiten, Firmenfeiern und Partys. Perfekt für Ihr Event!" />
        <meta name="keywords" content="Cocktailmaschine HUGO mieten, Cocktailmaschine für Hochzeit, Cocktailservice, Eventservice Taeger, Cocktails auf Knopfdruck, Party Cocktails, HUGO Cocktailmaschine für Events, Cocktail Catering" />
        <link rel="canonical" href="https://eventservice-taeger.de/cocktailmaschine" />

        {/* JSON-LD FAQ Schema */}
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "Wie funktioniert HUGO?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "HUGO arbeitet mit hochpräzisen Pumpen, die eine gleichbleibend hohe Cocktailqualität sicherstellen. Jede Zutat wird exakt dosiert, um den perfekten Cocktail zu kreieren."
                }
              },
              {
                "@type": "Question",
                "name": "Sind auch weniger als 100 Cocktails möglich?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Die Mindestabnahme von 100 Cocktails ist im Basispaket enthalten. Kleinere Mengen sind aus wirtschaftlichen Gründen nicht möglich."
                }
              },
              {
                "@type": "Question",
                "name": "Wie lange dauert der Aufbau der Maschine?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Der Aufbau von HUGO dauert etwa 30 Minuten. Die Einweisung in die Bedienung nimmt maximal 10 Minuten in Anspruch."
                }
              },
              {
                "@type": "Question",
                "name": "Was passiert, wenn Zutaten ausgehen?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Wenn eine Zutat leer ist, erhalten die eingewiesenen Personen automatisch eine SMS-Benachrichtigung."
                }
              },
              {
                "@type": "Question",
                "name": "Wie viele Cocktails sollte ich pro Person einplanen?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Für Abendveranstaltungen empfehlen wir, etwa 4 bis 5 Cocktails pro Person einzuplanen."
                }
              },
              {
                "@type": "Question",
                "name": "Stoppt HUGO automatisch, wenn die bestellte Menge erreicht ist?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "HUGO arbeitet weiter, solange noch Zutaten vorhanden sind. Die Anzahl der servierten Cocktails kann überwacht werden."
                }
              },
              {
                "@type": "Question",
                "name": "Müssen die Gläser nach der Veranstaltung gereinigt werden?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Ja, die Gläser sollten gespült zurückgegeben werden, da sie spülmaschinengeeignet sind."
                }
              },
              {
                "@type": "Question",
                "name": "Wie erfolgt die Bezahlung?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "50% des Basispakets sind bei Auftragsbestätigung zu zahlen. Der Restbetrag wird eine Woche vor der Veranstaltung fällig."
                }
              },
              {
                "@type": "Question",
                "name": "Ist HUGO laut?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "HUGO erzeugt Geräusche während der Zubereitung, diese sind jedoch bei laufender Musik kaum wahrnehmbar."
                }
              },
              {
                "@type": "Question",
                "name": "Wie lange dauert die Zubereitung eines Cocktails?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Ein Cocktail wird in der Regel in unter 10 Sekunden zubereitet."
                }
              },
              {
                "@type": "Question",
                "name": "Kann HUGO auch auf Kindergeburtstagen eingesetzt werden?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Ja, HUGO eignet sich auch für Kinderveranstaltungen, bei denen nur alkoholfreie Cocktails serviert werden."
                }
              }
            ]
          }
          `}
        </script>

        {/* JSON-LD Produkt Schema */}
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "Cocktailmaschine HUGO",
            "image": "https://eventservice-taeger.de/images/cocktail_1.jpeg",
            "description": "Mieten Sie die Cocktailmaschine HUGO – perfekt für Events mit frisch gemixten Cocktails auf Knopfdruck.",
            "sku": "HUGO-001",
            "brand": {
              "@type": "Brand",
              "name": "Eventservice Taeger"
            },
            "offers": {
              "@type": "Offer",
              "url": "https://eventservice-taeger.de/cocktailmaschine",
              "priceCurrency": "EUR",
              "price": "150.00",
              "priceValidUntil": "2024-12-31",
              "availability": "https://schema.org/InStock"
            }
          }
          `}
        </script>
      </Helmet>

      {/* Überschrift und Untertitel */}
      <div className="container mx-auto px-6 py-6 text-center">
  <h1 className="text-black text-4xl font-bold mb-4">Cocktailmaschine HUGO</h1>
  <p className="text-black text-xl">
    Cocktailcatering mal anders: Die perfekte Lösung für Ihre Feier – frisch gemixte Cocktails auf Knopfdruck.
  </p>
</div>


      {/* Karussell */}
      <CarouselSection />

      {/* Was kann HUGO */}
      <FeaturesSection />

      {/* Cocktailkarte */}
      <CocktailkarteSection />

      {/* Preisliste */}
      <PreislisteSection />

      {/* Testimonials */}
      {/*<TestimonialsSection />*/}

      {/* Ablauf */}
      <AblaufSection />

      {/* Anfrage */}
      <AnfrageSection />

      {/* FAQ Sektion */}
      <FAQSection />
    </div>
  );
}

export default Cocktailmaschine;
