import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

function UeberUns() {
  return (
    <div>
      {/* React Helmet für SEO */}
      <Helmet>
        <title>Über Uns - Eventservice Taeger | Partyzelte & Cocktailmaschine HUGO</title>
        <meta
          name="description"
          content="Lernen Sie Eventservice Taeger kennen: Ein wachsender Betrieb, der Partyzelte und eine einzigartige Cocktailmaschine bietet."
        />
        <meta name="keywords" content="Eventservice, Partyzelte, Cocktailmaschine, Herford, OWL, Niedersachsen" />
        <link rel="canonical" href="https://eventservice-taeger.de/ueber-uns" />
      </Helmet>

      {/* Überschrift und Untertitel */}
      <div className="container mx-auto px-6 py-6 text-center">
        <h1 className="text-black text-4xl font-bold mb-4">Über Uns</h1>
        <p className="text-black text-xl">
          Erfahren Sie mehr über unsere Mission und unsere Leidenschaft, besondere Feiern für alle möglich zu machen.
        </p>
      </div>

      <div className="container mx-auto px-6 py-6">
        <div className="space-y-8 text-left">

          {/* Unsere Geschichte */}
          <div className="border rounded-lg shadow-md overflow-hidden p-6">
            <h2 className="text-2xl font-bold mb-4">Unsere Geschichte</h2>
            <p className="text-gray-700 mb-4">
              Eventservice Taeger ist ein kleiner, aber stetig wachsender Betrieb, der aus unserer eigenen Erfahrung entstanden ist. Als wir unsere Hochzeit planten, stellten wir fest, wie teuer Eventservices sein können, besonders wenn es um Partyzelte und Cocktails geht. Das brachte uns auf die Idee: Hochwertige Feiern sollten für alle machbar und bezahlbar sein. Unser Ziel ist es, dir genau das zu ermöglichen – mit unseren wetterfesten Partyzelten und unserer einzigartigen Cocktailmaschine HUGO. 
            </p>
          </div>

          {/* Unsere Mission */}
          <div className="border rounded-lg shadow-md overflow-hidden p-6">
            <h2 className="text-2xl font-bold mb-4">Unsere Ziele</h2>
            <p className="text-gray-700 mb-4">
              Egal, ob du eine Hochzeit, einen runden Geburtstag oder ein entspanntes Sommerfest im Freien planst – unsere Zelte bieten die perfekte Lösung. Sie sind nicht nur robust und wetterfest, sondern lassen sich auch ganz einfach aufbauen und passen zu jedem Anlass. Und dann gibt es noch HUGO, die Cocktailmaschine, die auf Knopfdruck frische Cocktails in gleichbleibend hoher Qualität mixt. Perfekt für Feiern, die Gäste begeistern sollen – ohne großen Aufwand.
            </p>
          </div>

          {/* Unsere Vision */}
          <div className="border rounded-lg shadow-md overflow-hidden p-6">
            <h2 className="text-2xl font-bold mb-4">Unsere Vision</h2>
            <p className="text-gray-700 mb-4">
              Unsere Vision ist einfach: Wir möchten, dass deine Feier unvergesslich wird – ohne, dass hohe Kosten im Weg stehen. Mit unserer Cocktailmaschine HUGO bekommst du das Beste aus beiden Welten: Professionell gemixte Cocktails und jede Menge Spaß für deine Gäste. HUGO ist so gestaltet, dass jeder sein Lieblingsgetränk schnell und unkompliziert selbst auswählen kann. Und unsere Partyzelte? Sie schaffen eine gemütliche Atmosphäre, die jede Feier zu einem besonderen Erlebnis macht.
            </p>
          </div>

          {/* Warum Eventservice Taeger? */}
          <div className="border rounded-lg shadow-md overflow-hidden p-6">
            <h2 className="text-2xl font-bold mb-4">Warum Eventservice Taeger?</h2>
            <p className="text-gray-700 mb-4">
              Wir glauben daran, dass großartige Partys nicht kompliziert sein müssen. Deshalb bieten wir dir flexible Lösungen, die zu deinem Budget passen. Eventservice Taeger ist die perfekte Wahl, wenn du auf der Suche nach hochwertigen Partyzelten und einem außergewöhnlichen Cocktailcatering bist – unkompliziert, flexibel und bezahlbar.
            </p>
          </div>

          {/* Kontaktieren Sie uns */}
          <div className="border rounded-lg shadow-md overflow-hidden p-6">
            <h2 className="text-2xl font-bold mb-4">Kontaktieren Sie uns</h2>
            <p className="text-gray-700 mb-4">
              Lust auf mehr? Lass uns gemeinsam deine Feier planen und unvergessliche Momente schaffen! Besuche unsere <Link to="/kontakt" className="text-blue-500">Kontaktseite</Link>, um uns zu erreichen und mehr über unsere Services zu erfahren oder direkt zu buchen.
            </p>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default UeberUns;
