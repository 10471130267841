import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

// Bilder aus dem src-Verzeichnis importieren
import image1 from '../images/cocktail_1.JPG';
import image2 from '../images/cocktail_2.JPG';
import image3 from '../images/cocktail_3.JPG';
import image4 from '../images/cocktail_4.JPG';
import image5 from '../images/cocktail_5.JPG';
import image6 from '../images/cocktail_6.JPG';
import image7 from '../images/cocktail_7.JPG';
import image8 from '../images/cocktail_8.JPG';

// Liste der Bildquellen
const images = [image1, image2, image3, image4, image5, image6, image7, image8];

function CarouselSection() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  // Automatischer Bildwechsel alle 5 Sekunden
  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        handleNext();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [activeIndex, isPaused]);

  // Miniaturbild-Anklickfunktion
  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
    setIsPaused(true); // Manuelle Aktion pausiert den Timer
  };

  // Funktion zum Vor- oder Zurückwechseln
  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    setIsPaused(true); // Manuelle Aktion pausiert den Timer
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
    setIsPaused(true); // Manuelle Aktion pausiert den Timer
  };

  // Fallback für Bild, falls es nicht geladen werden kann
  const handleImageError = (e) => {
    e.target.src = 'fallback-image.jpg'; // Pfad zu deinem Fallback-Bild
  };

  return (
    <div className="container mx-auto px-6 py-6 flex flex-col md:flex-row items-start gap-8">
      {/* Preloading der ersten 4 Bilder */}
      {images.slice(0, 4).map((image, index) => (
        <link key={index} rel="preload" href={image} as="image" />
      ))}

      {/* Große Bildanzeige */}
      <div className="relative bg-white shadow-lg rounded-lg p-4 md:flex-1 flex justify-center items-center">
        <img
          src={images[activeIndex]} // Standardbild
          srcSet={`${images[activeIndex]} 640w, ${images[activeIndex]} 960w, ${images[activeIndex]} 1200w, ${images[activeIndex]} 1800w`} // Verschiedene Bildgrößen
          sizes="(max-width: 640px) 640px, (max-width: 960px) 960px, (max-width: 1200px) 1200px, 1800px" // Bildschirmgrößen
          alt={`Cocktailmaschine Bild ${activeIndex + 1}`}
          className="image-4by3" // Verwende die CSS-Klasse für das 4:3-Seitenverhältnis
          width="960"
          height="720"
          onError={handleImageError} // Fallback hinzufügen
        />
        {/* Pfeile für Vor/Zurück */}
        <button
          onClick={handlePrev}
          aria-label="Vorheriges Bild" // Zugänglicher Name für Screenreader
          className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-black p-3 rounded-full shadow-md"
        >
          <FaArrowLeft size={20} />
        </button>
        <button
          onClick={handleNext}
          aria-label="Nächstes Bild" // Zugänglicher Name für Screenreader
          className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-200 hover:bg-gray-300 text-black p-3 rounded-full shadow-md"
        >
          <FaArrowRight size={20} />
        </button>
      </div>

      {/* Miniaturbild-Auswahl */}
      <div className="bg-white shadow-lg rounded-lg p-4 md:flex-1">
        <div className="grid grid-cols-4 gap-4">
          {images.map((image, index) => (
            <div key={index}>
              <img
                src={image} // Verwende hier das Standardbild
                srcSet={`${image} 100w, ${image} 200w, ${image} 300w`} // Verwende kleinere Größen für die Thumbnails
                sizes="(max-width: 768px) 100px, 150px" // Je nach Bildschirmgröße kleinere Bilder verwenden
                alt={`Thumbnail ${index + 1}`}
                className={`image-4by3 cursor-pointer rounded-lg ${
                  activeIndex === index
                    ? 'border-4 border-blue-500'
                    : 'border border-gray-300'
                }`}
                width="100"
                height="75"
                onClick={() => handleThumbnailClick(index)}
                onError={handleImageError} // Fallback hinzufügen
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CarouselSection;
