import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const handleLinkClick = () => {
    setIsOpen(false); // Menü schließen
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollt zum Anfang der Seite
  };

  return (
    <nav className="bg-gray-100 sticky top-0 z-50 shadow-md">
      <div className="container mx-auto px-6 py-4 flex justify-between items-center">
        {/* Logo */}
        <div className="flex items-center">
          <Link to="/" onClick={handleLinkClick}>
            <img
              src="/logo_taeger.svg"
              alt="Eventservice Taeger Logo"
              className="block"
              style={{
                width: '170px',  // Feste Breite
                height: '63px'   // Feste Höhe
              }}
            />
          </Link>
        </div>

        {/* Hamburger Button für kleine Bildschirme */}
        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            aria-label="Hauptmenu"
            className="text-black focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'}
              />
            </svg>
          </button>
        </div>

        {/* Navigationslinks */}
        <div
          className={`${
            isOpen ? 'block' : 'hidden'
          } md:flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-0 md:space-x-6 w-full md:w-auto mt-4 md:mt-0 text-right md:text-left`}
        >
          <Link
            to="/"
            className="block text-black hover:text-gray-600 text-lg py-2 px-4"
            onClick={handleLinkClick}
          >
            Start
          </Link>
          <Link
            to="/ueber-uns"
            className="block text-black hover:text-gray-600 text-lg py-2 px-4"
            onClick={handleLinkClick}
          >
            Über Uns
          </Link>
          <Link
            to="/partyzelte"
            className="block text-black hover:text-gray-600 text-lg py-2 px-4"
            onClick={handleLinkClick}
          >
            Partyzelte
          </Link>
          <Link
            to="/cocktailmaschine"
            className="block text-black hover:text-gray-600 text-lg py-2 px-4"
            onClick={handleLinkClick}
          >
            Cocktailmaschine
          </Link>
          <Link
            to="/kontakt"
            className="block text-black hover:text-gray-600 text-lg py-2 px-4"
            onClick={handleLinkClick}
          >
            Kontakt
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
