import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';

function CocktailkarteSection() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold text-center mb-8">Cocktailkarte</h2>

      {/* Grid-Layout für größere Bildschirme */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        
        {/* Text zur Cocktailkarte */}
        <div className="bg-white p-6 shadow-lg rounded-lg flex flex-col justify-center items-center text-center">
          <p className="text-gray-700 mb-4 text-xl md:text-2xl">
            Zur Cocktailkarte geht's{' '}
            <a
              href="https://cocktails.eventservice-taeger.de"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              hier
            </a>{' '}
            entlang.
          </p>
        </div>

        {/* QR-Code */}
        <div className="bg-white p-6 shadow-lg rounded-lg flex justify-center">
          <QRCodeCanvas
            value="https://cocktails.eventservice-taeger.de"
            size={150}
            className="w-auto h-auto"
          />
        </div>
      </div>
    </div>
  );
}

export default CocktailkarteSection;
