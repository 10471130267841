import React from 'react';
import { Helmet } from 'react-helmet-async'; // Helmet importieren für SEO

function Impressum() {
  return (
    <div className="container mx-auto px-6 py-8">
      {/* React Helmet für SEO */}
      <Helmet>
        <title>Impressum - Eventservice Taeger</title>
        <meta
          name="description"
          content="Impressum des Eventservice Taeger. Hier finden Sie alle rechtlichen Informationen und Kontaktdaten."
        />
        <meta name="keywords" content="Impressum, Eventservice, Eventservice Taeger, Kontakt, rechtliche Informationen" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://eventservice-taeger.de/impressum" />
      </Helmet>

      <h1 className="text-3xl font-bold mb-4">Impressum</h1>

      <p className="text-gray-700 mb-4">
        Jonas Taeger<br />
        Eventservice Taeger<br />
        Auf der Heide 4<br />
        32051 Herford
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">Kontakt</h2>
      <p className="text-gray-700 mb-4">
        Telefon: 015164696160<br />
        E-Mail: <a href="mailto:eventservice.taeger@gmail.com" className="text-blue-500 hover:underline">eventservice.taeger@gmail.com</a>
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">EU-Streitschlichtung</h2>
      <p className="text-gray-700 mb-4">
        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:{" "}
        <a
          href="https://ec.europa.eu/consumers/odr/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:underline"
        >
          https://ec.europa.eu/consumers/odr/
        </a>.<br />
        Unsere E-Mail-Adresse finden Sie oben im Impressum.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">
        Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
      </h2>
      <p className="text-gray-700 mb-4">
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>

      <p className="text-gray-700 mt-4">
        Quelle:{" "}
        <a
          href="https://www.e-recht24.de/impressum-generator.html"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:underline"
        >
          https://www.e-recht24.de/impressum-generator.html
        </a>
      </p>
    </div>
  );
}

export default Impressum;
