import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { Helmet } from 'react-helmet-async'; // Für SEO

function Kontakt() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    telefon: '',
    datum: '',
    anfrage: '',
    nachricht: '',
    lieferadresse: '', // Unsichtbares Feld für Spam-Check
  });

  const [isChecked, setIsChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSent, setIsSent] = useState(false);
  const [lastSubmissionTime, setLastSubmissionTime] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (telefon) => {
    const phoneRegex = /^[0-9\-\+ ]{7,15}$/;
    return phoneRegex.test(telefon);
  };

  const validateDate = (datum) => {
    const today = new Date();
    const eventDate = new Date(datum);
    return eventDate > today;
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.name) newErrors.name = 'Name ist erforderlich';
    if (!formData.email || !validateEmail(formData.email)) newErrors.email = 'Gültige E-Mail ist erforderlich';
    if (!formData.telefon || !validatePhone(formData.telefon)) newErrors.telefon = 'Gültige Telefonnummer ist erforderlich';
    if (formData.datum && !validateDate(formData.datum)) newErrors.datum = 'Datum muss in der Zukunft liegen';
    if (!isChecked) newErrors.checkbox = 'Bitte akzeptieren Sie die Datenschutzerklärung';
    if (formData.lieferadresse) newErrors.lieferadresse = 'Spamverdacht'; // Spam-Check
    return newErrors;
  };

  const canSubmit = () => {
    if (!lastSubmissionTime) return true;
    const now = new Date();
    const timeDiff = (now - lastSubmissionTime) / 1000 / 60;
    return timeDiff >= 0.5; // maximal 2 Anfragen pro Minute
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else if (!canSubmit()) {
      setErrors({ rateLimit: 'Sie können maximal 2 Anfragen pro Minute senden.' });
    } else {
      emailjs
        .send(
          'service_jmdellb',
          'template_f9kttd2',
          formData,
          'qF8qUtoQU62Mzjs8D'
        )
        .then(
          (response) => {
            setIsSent(true);
            setErrors({});
            setFormData({ name: '', email: '', telefon: '', datum: '', anfrage: '', nachricht: '', lieferadresse: '' });
            setLastSubmissionTime(new Date());
          },
          (err) => {
            console.error('Fehler beim Senden der E-Mail:', err);
          }
        );
    }
  };

  const openWhatsAppChat = () => {
    const whatsappNumber = "+4915164696160"; // WhatsApp-Nummer
    const message = "Hallo, ich habe eine Anfrage zu Ihrem Eventservice.";
    const whatsappURL = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
  };

  return (
    <div className="container mx-auto px-6 py-8">
      {/* React Helmet für SEO */}
      <Helmet>
        <title>Kontakt - Eventservice Taeger</title>
        <meta
          name="description"
          content="Kontaktieren Sie den Eventservice Taeger für Ihre Fragen und Anfragen. Nutzen Sie unser Kontaktformular oder senden Sie uns eine Nachricht über WhatsApp."
        />
        <meta name="keywords" content="Kontakt Eventservice, Kontaktformular, Eventservice Taeger, Eventanfrage, Kontakt Partyservice" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://eventservice-taeger.de/kontakt" />
      </Helmet>

      <h2 className="text-3xl font-bold text-center mb-8">Kontakt</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Kontaktformular */}
        <form onSubmit={handleSubmit} className="bg-white p-6 shadow-lg rounded-lg">
          <h3 className="text-2xl font-bold mb-4">Kontaktformular</h3>

          {/* Name */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Name*
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.name ? 'border-red-500' : ''
              }`}
            />
            {errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>}
          </div>

          {/* E-Mail */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              E-Mail*
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.email ? 'border-red-500' : ''
              }`}
            />
            {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
          </div>

          {/* Telefon */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="telefon">
              Telefon*
            </label>
            <input
              type="tel"
              id="telefon"
              name="telefon"
              value={formData.telefon}
              onChange={handleInputChange}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.telefon ? 'border-red-500' : ''
              }`}
            />
            {errors.telefon && <p className="text-red-500 text-xs italic">{errors.telefon}</p>}
          </div>

          {/* Datum */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="datum">
              Datum der Veranstaltung
            </label>
            <input
              type="date"
              id="datum"
              name="datum"
              value={formData.datum}
              onChange={handleInputChange}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.datum ? 'border-red-500' : ''
              }`}
            />
            {errors.datum && <p className="text-red-500 text-xs italic">{errors.datum}</p>}
          </div>

          {/* Was anfragen */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="anfrage">
              Was möchten Sie anfragen?
            </label>
            <input
              type="text"
              id="anfrage"
              name="anfrage"
              value={formData.anfrage}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          {/* Nachricht */}
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nachricht">
              Nachricht / Veranstaltungsdaten
            </label>
            <textarea
              id="nachricht"
              name="nachricht"
              value={formData.nachricht}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-48 resize-none"
            />
          </div>

          {/* Unsichtbares Feld für Spam-Check */}
          <div style={{ display: 'none' }}>
            <input
              type="text"
              name="lieferadresse"
              value={formData.lieferadresse}
              onChange={handleInputChange}
            />
          </div>

          {/* Checkbox für Datenschutzerklärung */}
          <div className="mb-4">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                className="form-checkbox"
              />
              <span className="ml-2 text-gray-700">
                Ich akzeptiere die{' '}
                <a href="/datenschutz" target="_blank" className="text-blue-600 underline">
                  Datenschutzerklärung
                </a>
              </span>
            </label>
            {errors.checkbox && <p className="text-red-500 text-xs italic">{errors.checkbox}</p>}
          </div>

          {/* Fehlernachricht für Rate-Limiting */}
          {errors.rateLimit && <p className="text-red-500 text-xs italic">{errors.rateLimit}</p>}

          <div className="text-xs text-gray-600 italic mb-6">* Pflichtfelder</div>

          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Absenden
            </button>
          </div>

          {/* Erfolgsnachricht */}
          {isSent && (
            <div className="mt-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
              <strong className="font-bold">Vielen Dank!</strong>
              <span className="block sm:inline"> Ihre Nachricht wurde erfolgreich versendet.</span>
            </div>
          )}
        </form>

        {/* Kontaktdaten und WhatsApp */}
        <div className="bg-white p-6 shadow-lg rounded-lg">
          <h3 className="text-2xl font-bold mb-4">Kontaktinformationen</h3>
          <p className="text-gray-700 mb-4">
            <strong>Eventservice Taeger</strong><br />
            Auf der Heide 4<br />
            32051 Herford
          </p>
          <p className="text-gray-700 mb-4">
            Telefon: <a href="tel:+4915164696160" className="text-blue-600 underline">+4915164696160</a>
          </p>
          <p className="text-gray-700 mb-6">
            E-Mail: <a href="mailto:eventservice.taeger@gmail.com" className="text-blue-600 underline">eventservice.taeger@gmail.com</a>
          </p>
          <div className="flex justify-center mb-6">
            <button
              onClick={openWhatsAppChat}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              WhatsApp: Nachricht senden
            </button>
          </div>

          {/* Google Maps Karte */}
          <div className="flex justify-center mt-8">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2431.457498005259!2d8.623722275066062!3d52.127850167947205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ba23418b8d3bd7%3A0x43fda6a6a37e46ab!2sEventservice%20Taeger!5e0!3m2!1sde!2sde!4v1695913067850!5m2!1sde!2sde"
              width="100%"
              height="300"
              allowFullScreen=""
              loading="lazy"  // Lazy Loading hinzugefügt
              title="Eventservice Taeger Standort"
              className="rounded-lg shadow-lg"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Kontakt;
